import React from "react"
import Typed from "typed.js"
import styled from "styled-components"
import { device } from "../../utils"
import { t } from "@lingui/macro"
import { Text } from "../../components/Core"

const Element = styled.span`
  font-size: 45px;
  color: white;

  @media ${device.sm} {
    font-size: 61px;
  }

  @media ${device.lg} {
    font-size: 71px;
  }

  @media ${device.xl} {
    font-size: 75px;
  }
`

// Copied from here: https://medium.com/@d_danailov/typing-animation-with-react-nextjs-and-typed-js-d690bae3976b
class Typing extends React.Component {
  componentDidMount() {
    const options = {
      typeSpeed: 50,
      backSpeed: 50,
      startDelay: 1000,
      loop: false,
      cursorChar: "|",
      stringsElement: "#typed-strings",
    }
    this.typed = new Typed(this.el, options)
  }

  componentWillUnmount() {
    this.typed.destroy()
  }

  render() {
    return (
      <>
        <div id="typed-strings">
          <p>{t`Beratung`}</p>
          <p>{t`Entwicklung`}</p>
          <p>{t`Workshops`}</p>
          <p>{t`Daten Labeling`}</p>
          <p>{t`from Experts`}</p>
        </div>
        <Text mb={4} css={{ color: "white" }}>
          <Element>
            <Element
              style={{ whiteSpace: "pre" }}
              ref={(el) => {
                this.el = el
              }}
            />
          </Element>
        </Text>
      </>
    )
  }
}

export default Typing
