import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import { Title, Section, Box, Text } from "../../components/Core"
import imgCustomer1 from "../../assets/image/svg/undraw_weather_d9t2.svg"
import imgCustomer2 from "../../assets/image/svg/undraw_analysis_4jis.svg"
import imgCustomer3 from "../../assets/image/svg/undraw_recording_lywr.svg"
import imgCustomer4 from "../../assets/image/svg/undraw_face_recognition.svg"
import { t, Trans } from "@lingui/macro"

const ContentCard = ({
  className,
  image,
  name,
  company,
  children,
  ...rest
}) => (
  <Col sm="6" lg="3" css={{ display: "flex" }}>
    <Box
      bg="light"
      border="1px solid"
      borderColor="border"
      p="25px"
      borderRadius={10}
      className={`${className}`}
      css={{ marginBottom: 15 }}
      {...rest}
    >
      <div css={{ height: 25 }}>
        <Title
          fontWeight={300}
          variant="card"
          mb={0}
          css={{ textAlign: "center", fontSize: 24, marginBottom: 38 }}
        >
          {name}
        </Title>
      </div>
      <div css={{ height: 225 }}>
        <img
          src={image}
          alt={children}
          className="img-fluid"
          css={{ height: 300 }}
        />
      </div>
      <Text
        color="#000000"
        mt={4}
        textAlign="center"
        variant="small"
        fontSize={17}
      >
        {children}
      </Text>
    </Box>
  </Col>
)

const Applications = () => (
  <>
    <Section>
      <Container>
        <Row>
          <Col md="12">
            <Title className="text-center">
              <Trans>Anwendungsmöglichkeiten für Künstliche Intelligenz</Trans>
            </Title>
          </Col>
          <ContentCard name={t`Vorhersage`} image={imgCustomer1}>
            <Trans>
              Prognostizieren Sie z.B. das Verhalten Ihrer Kunden, um ihr
              Vertrauen zu gewinnen und ihre nächsten Schritte vorherzusagen.
            </Trans>
          </ContentCard>
          <ContentCard name={t`Empfehlungs-system`} image={imgCustomer2}>
            <Trans>
              Stellen Sie mit KI sicher, dass sich Ihre Kunden wertgeschätzt
              fühlen und immer alles finden, was sie brauchen.
            </Trans>
          </ContentCard>
          <ContentCard
            name={t`Natural Language Processing`}
            image={imgCustomer3}
          >
            <Trans>
              Nutzen Sie Natural Language Processing, um die Textanalyse in
              Ihren Projekten zu automatisieren.
            </Trans>
          </ContentCard>
          <ContentCard
            name={t`Bildanalyse und Klassifikation`}
            image={imgCustomer4}
          >
            <Trans>
              Analysieren und klassifizieren Sie Objekte in Bildern und Videos
              wie z.B. Personen, Gesichter, Texte, Autos, Straßenschilder...
            </Trans>
          </ContentCard>
        </Row>
      </Container>
    </Section>
  </>
)

export default Applications
