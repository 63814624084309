import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import { Title, Section, Box } from "../../components/Core"
import { device } from "../../utils"
import { Trans } from "@lingui/macro"

const ProcessStep = ({ color = "1f6286", children, ...rest }) => (
  <div
    data-aos="flip-left"
    data-aos-duration="750"
    data-aos-once="true"
    data-aos-easing="linear"
  >
    <Box
      bg="#1f6286"
      borderRadius={9}
      marginBottom={9}
      marginLeft={2}
      marginRight={2}
      minHeight={1}
      {...rest}
      css={`
        @media ${device.sm} {
          width: 100%;
          min-width: 16.66666667%;
        }
      `}
    >
      <div>
        <Title
          color="#fff"
          fontWeight={300}
          paddingTop={10}
          paddingBottom={10}
          textAlign="center"
          fontSize={14}
          variant="card"
          mb={0}
        >
          {children}
        </Title>
      </div>
    </Box>
  </div>
)

const Process = () => (
  <>
    <Section>
      <Container>
        <Row>
          <Col md="12">
            <Title className="text-center">
              <Trans>Unser Beratungsprozess und Projektdurchlauf</Trans>
            </Title>
          </Col>
          <Col md="2" className="px-1">
            <ProcessStep>
              <Trans>
                Lassen Sie <br />
                uns reden
              </Trans>
            </ProcessStep>
          </Col>
          <Col md="2" className="px-1">
            <ProcessStep>
              <Trans>
                Möglichkeiten <br />
                evaluieren
              </Trans>
            </ProcessStep>
          </Col>
          <Col md="2" className="px-1">
            <ProcessStep>
              <Trans>
                Algorithmus <br />
                auswählen
              </Trans>
            </ProcessStep>
          </Col>
          <Col md="2" className="px-1">
            <ProcessStep>
              <Trans>
                Technologie <br />
                auswählen
              </Trans>
            </ProcessStep>
          </Col>
          <Col md="2" className="px-1">
            <ProcessStep>
              <Trans>
                Lösung <br />
                entwickeln
              </Trans>
            </ProcessStep>
          </Col>
          <Col md="2" className="px-1">
            <ProcessStep>
              <Trans>
                Lösung <br />
                integrieren
              </Trans>
            </ProcessStep>
          </Col>
        </Row>
      </Container>
    </Section>
  </>
)

export default Process
