import React from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"
import { LocalizedLink } from "gatsby-theme-i18n"
import { Trans } from "@lingui/macro"

import { Title, Button, Section, Box } from "../../components/Core"

import Typing from "./Typing"

import FixedHeroImage from "../../components/FixedHeroImage"
import { getImage } from "gatsby-plugin-image"

const Banner = styled(FixedHeroImage)`
  height: 700px;
  max-height: 100vh;
`

const Hero = () => {
  const { hero } = useStaticQuery(
    graphql`
      query {
        hero: file(relativePath: { eq: "jpeg/landing.jpg" }) {
          childImageSharp {
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    `,
  )
  const backgroundFluidImageStack = [
    `linear-gradient(180deg,rgba(32,98,135,.6),rgba(32,98,135,.6))`,
    getImage(hero.childImageSharp.gatsbyImageData),
  ]
  return (
    <>
      {/* <!-- Hero Area --> */}
      <Banner image={backgroundFluidImageStack} py={[5, null, "80px", 6]}>
        <Section>
          <div className="pt-5"></div>
          <Container>
            <Row>
              <Col
                md="12"
                lg="12"
                className="order-lg-1"
                data-aos="fade-right"
                data-aos-duration="750"
                data-aos-delay="000"
                data-aos-once="true"
              >
                <Box py={[null, null, null, 5]} pr={5} pt={[4, null]}>
                  <Title variant="hero" css={{ color: "white" }}>
                    <Trans>Machine Learning & KI</Trans>
                  </Title>
                  <Typing />
                  <LocalizedLink to="/contact">
                    <Button variant="outline" mb={3} mr={3}>
                      <Trans>Kontakt aufnehmen</Trans>
                    </Button>
                  </LocalizedLink>
                  {/*
                  <LocalizedLink to="/contact">
                    <Button mb={3}><Trans>Leistungen</Trans></Button>
                  </LocalizedLink>
                  */}
                </Box>
              </Col>
            </Row>
          </Container>
        </Section>
      </Banner>
    </>
  )
}

export default Hero
