import React from "react"
import Hero from "../sections/landing/Hero"
import Clients from "../sections/landing/Clients"
import Feature from "../sections/landing/Feature"
import AiCircles from "../sections/landing/AiCircles"
import Process from "../sections/landing/Process"
import Applications from "../sections/landing/Applications"
import Newsletter from "../sections/landing/Newsletter"
import PageWrapper from "../components/PageWrapper"
import { t } from "@lingui/macro"
import SEO from "../components/SEO"
import JsonLd from "../components/SEO/jsonld"

const IndexPage = ({ location }) => {
  return (
    <>
      <SEO
        pathname={location.pathname}
        title={t`Beratung & Engineering für Künstliche Intelligenz`}
        description={t`Die AMAI GmbH hilft Ihnen, Künstliche Intelligenz in Ihre Prozesse und Produkte zu integrieren. Auf dem Gebiet des Machine Learning, Deep Learning sind wir Experten. Wir beraten Sie und realisieren Ihr KI Projekt.`}
      />
      <JsonLd
        json={{
          "@context": "http://schema.org",
          "@type": "Organization",
          name: "AMAI",
          legalName: "AMAI GmbH",
          url: "https://www.am.ai",
          logo: {
            "@type": "ImageObject",
            url: "https://www.am.ai/AMAI-OpenGraph.png",
          },
          foundingDate: "2018",
          address: {
            "@type": "PostalAddress",
            streetAddress: "Schwarzwaldstraße 39",
            postalCode: "76137",
            addressLocality: "Karlsruhe",
            addressRegion: "Baden-Württemberg",
            addressCountry: "DE",
          },
          sameAs: [
            "https://linkedin.com/company/amai-gmbh",
            "https://www.xing.com/companies/amaigmbh",
            "https://twitter.com/amaigmbh",
            "https://github.com/AMAI-GmbH",
          ],
          contactPoint: [
            {
              "@type": "ContactPoint",
              telephone: "+49 721 27664476",
              email: "hi@am.ai",
              contactType: "technical support",
              contactOption: "TollFree",
              areaServed: ["DE", "CH", "AT", "FR", "US", "CA"],
            },
          ],
        }}
      />
      <PageWrapper footerDark headerDark>
        <Hero />
        <Clients />
        <Feature />
        <AiCircles />
        <Process />
        <Applications />
        <Newsletter />
      </PageWrapper>
    </>
  )
}
export default IndexPage
