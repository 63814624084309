import React from "react"
import styled from "styled-components"
import { Container } from "react-bootstrap"
import { Title, Box, Section } from "../../components/Core"
import { Trans } from "@lingui/macro"

const AiCirclesContainer = styled(Container)`
  position: relative;
  display: block;
  width: 435px;
  height: 410px;
  margin-top: 30px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 479px) {
    width: 358px;
    height: 358px;
  }
`
const CircleTitle = styled(Title)`
  color: #fff;
  line-height: 27px;
  font-size: 27px;
  text-align: center;
  margin: auto;
  @media screen and (max-width: 479px) {
    font-size: 18px;
  }
`

const CircleA = styled(Box)`
  position: relative;
  width: 250px;
  height: 250px;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  border-radius: 100%;
  background-image: linear-gradient(
    180deg,
    rgba(31, 98, 134, 0.7),
    rgba(31, 98, 134, 0.7)
  );
  @media screen and (max-width: 479px) {
    width: 150px;
    height: 150px;
  }
`
const Circle = ({ children }) => (
  <div data-aos="zoom-in" data-aos-duration="750" data-aos-once="true">
    <CircleA>
      <div className="container d-flex align-items-center justify-content-center">
        <CircleTitle variant="card" mb={0}>
          {children}
        </CircleTitle>
      </div>
    </CircleA>
  </div>
)

const AiCircles = () => (
  <Section>
    <Title className="text-center">
      <Trans>AMAI ist Experte für Künstliche Intelligenz</Trans>
    </Title>
    <AiCirclesContainer>
      <div
        css={`
          left: 0px;
          top: 47px;
          position: relative;
          @media screen and (max-width: 479px) {
            left: 0px;
            top: 25px;
          } ;
        `}
      >
        <Circle>
          <Trans>
            Fortgeschrittene
            <br />
            Algorithmen & Modelle
          </Trans>
        </Circle>
      </div>
      <div
        css={`
          left: 96px;
          top: -44px;
          position: relative;
          @media screen and (max-width: 479px) {
            left: 59px;
            top: -20px;
          } ;
        `}
      >
        <Circle>
          <Trans>
            Big <br />
            Data
          </Trans>
        </Circle>
      </div>
      <div
        css={`
          left: -96px;
          top: -294px;
          position: relative;
          @media screen and (max-width: 479px) {
            left: -60px;
            top: -168px;
          } ;
        `}
      >
        <Circle>
          <Trans>
            (Cloud) <br />
            Computing <br />
            Power <br />
          </Trans>
        </Circle>
      </div>
      <div
        css={`
          top: -480px;
          position: relative;
          @media screen and (max-width: 479px) {
            top: -297px;
          } ;
        `}
      >
        <div className="container d-flex align-items-center justify-content-center">
          <CircleTitle variant="card" mb={0}>
            <Trans>KI</Trans>
          </CircleTitle>
        </div>
      </div>
    </AiCirclesContainer>
  </Section>
)

export default AiCircles
