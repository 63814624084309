import React from "react"
// import styled from "styled-components"
import { Container, Row, Col } from "react-bootstrap"
import { Title, Section, Box, Text } from "../../components/Core"
import IconAi from "../../assets/image/svg/icon_artificial-intelligence.svg"
import IconDeepLearning from "../../assets/image/svg/icon_deep-learning.svg"
import IconMachineLearning from "../../assets/image/svg/icon_machine-learning.svg"
import IconDeployment from "../../assets/image/svg/icon_deployment.svg"
import { t, Trans } from "@lingui/macro"

/*
const ShapeTopRight = styled(Box)`
  position: absolute;
  top: 0;
  right: 0px;
`;

// to top: import imgL1FeatureCurve from "../../assets/image/svg/l1-curve-feature.svg"
const ShapeBottmRight = styled(Box)`
  position: absolute;
  bottom: -10px;
  left: 0;
  width: 100%;
  img {
    min-width: 100%;
  }
`
*/

const FeatureCard = ({
  color = "primary",
  iconName,
  title,
  img,
  children,
  ...rest
}) => (
  <Col md="6" css={{ display: "flex" }}>
    <Box
      bg="light"
      py="40px"
      px="30px"
      borderRadius={10}
      textAlign="center"
      mb={4}
      {...rest}>
      <Box size={75} className="mx-auto">
        <img src={iconName} alt="" className="img-fluid" />
      </Box>
      <div>
        <Text
          color="#1f6286"
          as="h3"
          fontSize={3}
          fontWeight={500}
          letterSpacing={-0.75}
          my={3}
          className="text-uppercase">
          {title}
        </Text>
        <Text variant="small" fontSize={17} color="#000000">
          {children}
        </Text>
      </div>
    </Box>
  </Col>
)

const Feature = () => {
  return (
    <>
      <Section bg="primary" className="position-relative">
        {/*<ShapeTopRight
        data-aos="fade-left"
        data-aos-duration="750"
        data-aos-once="true"
      >
        <img src={imgL1FeatureOval} alt="" className="img-fluid" />
      </ShapeTopRight>*/}
        {/*
        <ShapeBottmRight>
          <img src={imgL1FeatureCurve} alt="" className="img-fluid" />
        </ShapeBottmRight>*/}
        <Container>
          <Row className="align-items-center">
            <Col lg="12">
              <Title className="text-center" color="light">
                <Trans>Wir machen Ihre Produkte und Prozesse intelligent</Trans>
              </Title>
            </Col>
            <Col lg="12" className="order-lg-1 mt-5 mt-lg-0">
              <Row>
                <FeatureCard
                  iconName={IconAi}
                  title={t`Künstliche Intelligenz`}>
                  <Trans>
                    Wir identifizieren das Potential von Künstlicher Intelligenz
                    (KI) für Ihre Produkte und Prozesse und unterstützen Sie mit
                    unserem Know-how in der Umsetzung von Projekten.
                  </Trans>
                </FeatureCard>
                <FeatureCard
                  iconName={IconMachineLearning}
                  title={t`Machine Learning`}>
                  <Trans>
                    AMAI bietet Beratung zur Algorithmenauswahl, zur
                    Datenbereinigung, zur Normalisierung und zum Trainieren von
                    Künstlicher Intelligenz. Außerdem entwickeln wir passende
                    Lösungen für den Einsatz in Ihrem Unternehmen.
                  </Trans>
                </FeatureCard>
                <FeatureCard
                  iconName={IconDeepLearning}
                  title={t`Deep Learning`}>
                  <Trans>
                    Als Teilbereich des Machine Learning lässt sich Deep
                    Learning für viele Anwendungsfälle einsetzen. AMAI
                    unterstützt Sie in der Erstellung neuronaler Netze und
                    darin, diese zu trainieren und auszuführen.
                  </Trans>
                </FeatureCard>
                <FeatureCard
                  iconName={IconDeployment}
                  title={t`Technologieauswahl und Inbetriebnahme`}>
                  <Trans>
                    Wir helfen Ihnen, die für Ihren Anwendungsfall passenden
                    Technologien auszuwählen, zu evaluieren und diese in Ihre
                    Produkte und Prozesse zu integrieren.
                  </Trans>
                </FeatureCard>
              </Row>
            </Col>
          </Row>
        </Container>
      </Section>
    </>
  )
}

export default Feature
